import React from "react"
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      "בכל פיתוח של מוצר טכנולוגי (אפליקציה, אתר, תוכנה, מוצר וכו') יש להתייחס למרכיב עיצוב חוויית המשתמש. חוויית המשתמש ui/ux היא מילת מפתח בתחום העיצוב הגרפי ובפיתוח מוצרים טכנולוגיים, וכדי לעצב ממשקי משתמש נוחים וידידותיים יש לשמור ולהקפיד על 6 כללי עיצוב שאסור להפר!",
      "למרות שיש קווי דמיון בין שני המושגים יש להם משמעות שונה, מאחר ו-UX הוא למעשה חוויית המשתמש, בעוד UI עוסק בעיצוב הממשק המיועד למשתמש, כלומר מתמקדים בחלק הוויזואלי של חוויית השימוש במוצר הטכנולוגי. ראוי לציין כי חברות וארגונים משקיעים משאבים עצומים לצורך יצירת חוויית משתמש חיובית ומובנת, והמטרה היא לספק למשתמשי הקצה חוויה אופטימלית ואינטואיטיבית. שמירה על הכללים בעיצוב ממשק וחוויית המשתמש יסייעו למשתמשים לנווט את דרכם בקלות, ליצור אינטראקציה טובה עם המערכת או המוצר ובנוסף מבטיחים שהמשתמשים יהפכו ללקוחות נאמנים ועקביים."
    ],
  },
  section2: {
    h: " 1. התאמת הממשק לעולם האמיתי",
    p: [
      "מעצב UX/UI מנוסה מתאים את סביבת המשתמש במערכת או במוצר לשפה, למושגים, לביטויים ולרעיונות המוכרים למשתמש. המערכת או המוצר צריכים לדבר בשפה המובנת למשתמש ולהתאים להגיון ולסדר שבו הוא רגיל לחוות מוצרים וממשקים בזירות הדיגיטל המגוונות. ",
      <>
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>בתהליך אפיון ועיצוב UX/UI</Link>
        {` מאפיינים את סביבת המשתמש כדי לבחור נכון את השפה והעיצוב של הממשקים. חשוב לזכור כי בעבר הלא רחוק המסכים של המשתמשים היו מוגבלים מבחינת עיצוב ולרוב המוצרים והמערכות כללו פקודות טקסט בלבד, אך ההתקדמות הטכנולוגית מאפשרת כיום למעצבים להתאים את שפת הממשק בעזרת סמלים ותמונות קטנות, והשימוש בסמלים חזותיים הפך לנפוץ ולמקובל בתעשייה. עם זאת, בעת פיתוח ממשק UX/UI חשוב לוודא כי משתמשים בסמלים ויזואליים ברורים ומובנים עבור המשתמשים כדי לא לבלבל אותם ועל מנת לסייע להם לנווט את דרכם בקלות ובנוחות במערכת או במוצר, לכן כלל הברזל בתחום הוא להשתמש בסמלים המותאמים לשפת המשתמשים בעולם האמיתי. `}
      </>
    ],
  },
  section3: {
    h: "2. עקביות בממשק המשתמש",
    p: [
      "כלל עיצוב חשוב שאין להפר. יש לשמור על עקביות בעיצוב ממשק המשתמש וחשוב לשמור על נוסח אחיד. המשתמשים במערכת/מוצר חייבים לבצע פעולות בקלות ובמהירות, ללא בלבול מיותר. יש להתייחס לשני סוגיים עיקריים של עקביות – פנימית וחיצונית. העקביות הפנימית במערכת נועדה להבטיח שפה אחידה במוצר/מערכת, לדוגמה: כפתור המופיע במקומות שונים חייב להיראות דומה בכל התפריטים בממשק ולבצע פעולה זהה. אם משלבים בממשק כפתור עם סמל של זכוכית מגדלת, לחיצה עליו תפנה את המשתמש לאופציית חיפוש בלבד ולא לפעולה אחרת.",
      "עקביות חיצונית חשובה לא פחות מאחר והיא מתייחסת לנורמות מקובלות במערכות אחרות, ויש לשלב סמלים ויזואליים המוכרים למשתמשים מהעולם האמיתי ומשימוש במערכות אחרות, לדוגמה כל המשתמשים מכירים את הסמל של עגלת הקניות, לכן מעצב ui ux לא נדרש להמציא את הגלגל מחדש."
    ]
  },
  section4: {
    h: "3. מניעת טעויות והסתבכות בממשק",
    p: [
      "עיצוב זהיר של ממשק המערכת או המוצר מונע טעויות ובעיות בקרב המשתמשים. מעצב מנוסה מציג באופן ברור את התנאים לפעולות השונות שהמשתמשים מבצעים במוצר/מערכת, וכדי למנוע הסתבכות של המשתמש מומלץ להציג את התנאים לפני ביצוע הפעולה, בעיקר פעולות הנחשבות לרגישות כמו מחיקת קבצים, רכישת מנוי, שליחת הודעה בתפוצה רחבה וכיוצא בזה.",
      "עיצוב ברור של ממשק המוצר/המערכת מונע מהמשתמשים לבצע פעולות שהם אינם בטוחים לגביהן, וכך חוסכים מהם אכזבה, טעויות ותחושות של חוסר אמון במוצר או במערכת. זהו כלל ברזל שחשוב ליישם בכל אפיון ועיצוב UX/UI."
    ]
  },
  section5: {
    h: "4. הוראות הפעלה ושימוש במוצר",
    p: [
      "משתמש ממוצע במוצר או במערכת זקוק להוראות שימוש גלויות וברורות אחרת הוא עלול לסבול מעומס קוגניטיבי ומטעויות שימוש. מעצב ממשקים מקצועי ומנוסה חייב להקפיד על תיאורי טקסטים ובחירת אייקונים וסמלים ויזואליים ברורים ולא להסתמך על זיכרון המשתמשים. לכן, חשוב לבחור אייקונים וכפתורים מוכרים לכל המשתמשים, לדוגמה סמל של עמוד הבית בממשק מובן לכל המשתמשים במוצר או במערכת ככפתור המאפשר מעבר מהיר לעמוד הבית באתר או לדף הפתיחה של המוצר.",
      "אם חייבים לעצב אייקונים חדשים לממשק חשוב לעצב סמלים ברורים ולהוסיף להם תיאור טקסט כדי לאפשר למשתמשים להבין מה הכפתור מבצע וכך גם מפחיתים את העומס הקוגניטיבי."
    ]
  },
  section6: {
    h: "5. עיצוב מינימליסטי עם אסתטיקה גבוהה",
    p: [
      "אחד מכללי הברזל החשובים ביותר בתהליך עיצוב ממשקי מערכת או מוצר הוא להקפיד על עיצוב מינימליסטי ונראות מצוינת. בתהליך העיצוב יש לשאוף לפשטות במסכים המוצר או המערכת לכן אין להעמיס את המסכים עם כפתורים ולאלמנטים נוספים. יש להשקיע זמן ומחשבה על מנת להגיע לחוויית משתמש ui/ux נוחה ופשוטה, עם מסר אחיד ואסתטיקה מרשימה וגבוהה המסייעת לכל המשתמשים להתמקד במשימה מבלי להסתבך עם טעויות וללא הסחת דעת.",
      "לכן, יש לעצב מסכים המכילים רק מידע רלוונטי עבור המשתמשים, להפחית את העומס הוויזואלי ולהוריד מהמסכים השונים כפתורים וטקסטים שאינם חשובים. כמו כן, כדאי לעצב מסכים נקיים ואסתטיים עם רווחים וללא מסגרות מיותרות, כך מבטיחים ממשקים ידידותיים למשתמש."
    ]
  },
  section7: {
    h: "6. גמישות ואינטראקציה יעילה עם ממשק משתמש",
    p: [
      "עיצוב נכון של חוויית המשתמש UX/UI מבטיח התאמה בין הפעולות שניתן לבצע במוצר או במערכת לבין מאפייני השימוש של המשתמשים החדשים והמנוסים. משתמשי מנוסים נוטים לבצע פעולות החוזרות על עצמן בדרך מסוימת לעומת משתמשים חדשים שלרוב רק ילחצו על הכפתורים במסכים ולא ינסו טכניקות מתקדמות יותר כמו שימוש בלחצן הימני בעכבר, או פעולות המבוססות על קיצורי דרך אפשריים.",
      "לכן, יש לעצב ממשקים המתאימים לשני סוגי המשתמשים – מנוסים ומתחילים, וחשוב לאפשר לכל משתמש להחליט איך להשתמש במערכת בצורה האופטימלית והנוחה ביותר עבורו. חשוב לציין כי קיצורי דרך משפרים את חוויית המשתמש במוצר או במערכת, וגם אם משתמשים חדשים לא יעשו בהם שימוש, יש לכלול אותם כדי לשפר ולשדרג את חוויית המשתמש ולייעל את האינטראקציה עם המשתמשים המנוסים."
    ]
  },
};