import * as React from "react";
import { graphql } from 'gatsby';
import Blog, { BlogSubHeader, BlogList, BlogListItem } from '../../components/Blog';
import { Paragraph } from "../../components/Common";
import { HE } from '../../blogs/design-rules';
import { HowToDoSoftwareDesignRight, WebDevelopmentProcesses, DesignRules, EmphasisToConsiderWhenDesigningSaaSApp } from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const articleSchemaData = {
  headline: "6 Design rules",
  description: "SEO description design rule",
  image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fdesign-rules.jpg?alt=media&token=3e2254aa-b4b7-43c8-8f28-463dd18718a8",
  datePublished: '2022-02-08'
};

const DesignRulesBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema("6 Design rules", BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={DesignRules.url}
      banner="/design-rules/banner.jpg"
      relatedBlogs={[HowToDoSoftwareDesignRight, EmphasisToConsiderWhenDesigningSaaSApp, WebDevelopmentProcesses]}
      title="6 Design rules"
      seo={{
        title: 'SEO title design rule',
        description: 'SEO description design rule',
        image: 'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Fdesign-rules.jpg?alt=media&token=3e2254aa-b4b7-43c8-8f28-463dd18718a8',
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogList>

        <BlogListItem>
          <BlogSubHeader>
            {HE.section2.h}
          </BlogSubHeader>
          {HE.section2.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

        <BlogListItem>
          <BlogSubHeader>
            {HE.section3.h}
          </BlogSubHeader>
          {HE.section3.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

        <BlogListItem>
          <BlogSubHeader>
            {HE.section4.h}
          </BlogSubHeader>
          {HE.section4.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

        <BlogListItem>
          <BlogSubHeader>
            {HE.section5.h}
          </BlogSubHeader>
          {HE.section5.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

        <BlogListItem>
          <BlogSubHeader>
            {HE.section6.h}
          </BlogSubHeader>
          {HE.section6.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

        <BlogListItem>
          <BlogSubHeader>
            {HE.section7.h}
          </BlogSubHeader>
          {HE.section7.p.map(p => <Paragraph>{p}</Paragraph>)}
        </BlogListItem>

      </BlogList>
    </Blog>
  )
}

export default DesignRulesBlog;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;